import { Paragraph } from "~/components/ui/typography";
import clsx from "clsx";
import { HTMLProps } from "react";

export function Label({
	className,
	isFormLabel,
	...labelProps
}: HTMLProps<HTMLLabelElement> & { isFormLabel?: boolean }) {
	return (
		<label className={clsx("mb-2 inline-block", className)} {...labelProps}>
			<Paragraph
				size="body-small"
				color="secondary"
				as={isFormLabel ? "span" : undefined}
			>
				{labelProps.children}
			</Paragraph>
		</label>
	);
}
